import { sprintf } from 'sprintf-js';

const AVATAR_ROOT = 'https://oss.1p3a.com/tom_love/avatar';

export const getAvatarAddress = (uid, update_time) => {
  // 已加防盗链，本地无法打开
  if (uid === undefined) {
    return `${AVATAR_ROOT}/no_avatar_middle.gif`;
  }
  const paddingUID = sprintf('%09d', uid).toString();
  const dir1 = paddingUID.substring(0, 3);
  const dir2 = paddingUID.substring(3, 5);
  const dir3 = paddingUID.substring(5, 7);
  const dir4 = paddingUID.substring(7);
  const url = `${AVATAR_ROOT}/${dir1}/${dir2}/${dir3}/${dir4}_avatar.jpg!d`;
  if (update_time) {
    return `${url}?m=${update_time}`;
  }
  return url;
};

export function getImageAddress(url) {
  if (url.includes('tom_love')) {
    return `https://oss.1p3a.com/${url}!p`;
  }
  return `https://oss.1p3a.com/common/${url}!p`;
}

export const bbsEndpoint = {
  LOGIN: (path) => `https://auth.1point3acres.com/login?url=${encodeURIComponent(path)}`,
  LOGOUT: (path) => `https://auth.1point3acres.com/logout?url=${encodeURIComponent(path)}`,
  BBS_USER_PROFILE: (uid) => `https://www.1point3acres.com/bbs/home.php?mod=space&uid=${uid}&do=profile`,
  REGISTER: 'https://auth.1point3acres.com/register',
  ABOUT_US: `https://www.1point3acres.com/bbs/aboutus.php`,
  PRIVACY: `https://www.1point3acres.com/privacy_policy.html`,
  CONTACT: `https://www.1point3acres.com/contact`,
};

export const cookieKeys = {
  BBS_UID_KEY: '4Oaf_61d6_cuid',
  TOM_LOVE_UID_KEY: '4Oaf_61d6_tom_ucenter_member_uid',
  APP_VERSION: 'app_version',
};

export const unitToText = (unit) => {
  return { 2: { text: '个月', num: 1 }, 3: { text: '年', num: 12 } }[unit];
};
